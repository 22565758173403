<template>
  <div class="container">
    <SelectStore @selectStore="handleSelectStore"/>
    <div class="right-container">
      <div class="list-container" :style="{height: height}">
        <div class="header">运单记录</div>
        <div class="toolbar">
          <el-input placeholder="查询关键字" v-model="keyword" :clearable="true" :style="{ width:'250px',marginTop: '15px',float: 'right'}">
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
        </div>
        <el-table :data="ordFreightList" highlight-current-row ref="table" v-loading="loading" :max-height="tableMaxHeight">
          <el-table-column label="序号" type="index" align="center" width="60px" :index="getIndex"></el-table-column>
          <el-table-column label="门店名称" align="center" width="140px" prop="sStore"></el-table-column>
          <el-table-column label="配送单号" align="center" width="135px" prop="cSys"></el-table-column>
          <el-table-column label="制单类型" align="center" width="60px" >
            <template #default="scope">
              {{ getFreightType(scope.row.bType) }}
            </template>
          </el-table-column>
          <el-table-column label="骑手" align="center" width="60px" prop="sRider"></el-table-column>
          <el-table-column label="距离" align="center" width="50px" prop="nDistance"></el-table-column>
          <el-table-column label="运费" align="center" width="65px" prop="nTotal" :formatter="priceFormatter"></el-table-column>
          <el-table-column label="状态" align="center" width="60px" >
            <template #default="scope">
              {{ getFreightStatus(scope.row.bStatus) }}
            </template>
          </el-table-column>
          <el-table-column label="下单时间" align="center" width="120px" prop="tCreate" :formatter="dateFormatter"></el-table-column>
          <el-table-column fixed="right" align="center" label="操作" width="70px">
            <template slot-scope="scope">
              <el-button type="text" @click="view(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          :layout="pageLayout"
          :total="total"
          :current-page="pageNum"
          :page-sizes="pageSelect"
          :page-size="pageSize"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :style="{marginTop: '15px'}">
        </el-pagination>
        <ViewOrdFreightDetail :visible.sync="showViewOrdFreightDetail" :sid="currentSid" @close="handleCloseViewOrdFreightDetail"/>
      </div>
    </div>
  </div>
</template>

<script>
import {getOrdFreightList} from "@/api/delivery";
import {freightTypeMap,freightStatusMap} from "@/utils/deliveryMap";
import {formatTime} from "@/utils/utils";
import SelectStore from "@/views/components/SelectStore";
import ViewOrdFreightDetail from "@/views/components/DeliveryOrder/ViewOrdFreightDetail";

export default {
  name: "deliveryOrder",
  components: {SelectStore,ViewOrdFreightDetail},
  data() {
    return {
      height: document.documentElement.clientHeight - 60 + "px",
      tableMaxHeight: document.documentElement.clientHeight - 250,
      currentSid:'',
      showViewOrdFreightDetail: false,
      rStores:[],
      keyword:'',
      ordFreightList:[],
      loading:false,
      total: 0, //总条数
      pageNum: 1, //当前页
      pageSize: 10, //每页显示条数
      pageSelect: [10, 20, 30, 50],
      pageLayout:"prev, pager, next,jumper,total,sizes",
    };
  },
  created() {
    //this.getOrdFreightList();
  },
  mounted() {

  },
  computed: {
    getFreightType() {
      return function(bType) {
        return freightTypeMap[bType] || '未知';
      };
    },
    getFreightStatus() {
      return function(bType) {
        return freightStatusMap[bType] || '未知';
      };
    },
  },
  methods: {
    getOrdFreightList() {
      let params = {
        search:this.keyword,
        rStores:this.rStores,
        pageNum:this.pageNum,
        pageSize:this.pageSize,
      }
      this.loading = true;
      getOrdFreightList(params).then(result => {
        if (result) {
          this.ordFreightList = result.rows;
          this.total = result.total;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    // 查询
    search(){
      this.pageNum = 1;
      this.getOrdFreightList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.getOrdFreightList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getOrdFreightList();
    },
    handleSelectStore(e) {
      this.rStores = e;
      this.pageNum = 1;
      this.getOrdFreightList();
    },
    view(item){
      this.currentSid = item.sid;
      this.showViewOrdFreightDetail = true;
    },
    handleCloseViewOrdFreightDetail() {
      this.showViewOrdFreightDetail = false;
    },
    getIndex(rowIndex) {
      return (this.pageNum - 1) * this.pageSize + rowIndex +1;
    },
    priceFormatter(row, column, cellValue) {
      return cellValue?'￥'+cellValue:'';
    },
    dateFormatter(row, column, cellValue) {
      return cellValue?formatTime(cellValue,"YYYY-MM-DD HH:mm"):'';
    },
  },
  watch: {
    rStores(newVal) {
    },
  },
};
</script>

<style lang="scss" scoped>
.container{
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 20px;
}
.right-container{
  float:left;
  width:calc(100% - 240px);
  height: 100%;
}
.list-container{
  width: 100%;
  border: 1px solid #ddd;
  padding: 0px 20px;
  .header{
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    padding-left: 5px;
    border-bottom: 1px solid #ddd;
  }
  .toolbar{
    width: 100%;
    height: 60px;
  }
}
</style>
